import React from 'react';
import { createRoot } from 'react-dom/client';
import Login from '../components/Login.js';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <Login>
  </Login>
);