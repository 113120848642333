import React from 'react';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';


export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      snackbarOpen: false
    };
  }

  render() {
    return (
      <>
        <form onSubmit={this.handleLogin}>
          <Box sx={{ display: 'flex', width: "100vw", height: "100vh", justifyContent: "center", alignItems: "center" }}>
            <div onKeyDown={this.handleKeyDown}>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '250px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: '15px', width: '100%' }}>
                  <TextField
                    sx={{background: "#FFFFFF", width: '100%' }}
                    id="username"
                    label="Username"
                    margin="normal"
                    variant="filled"
                    onChange={this.setUsername}
                    value={this.state.surname}
                  />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: '15px', width: '100%' }}>
                  <TextField
                    sx={{background: "#FFFFFF", width: '100%' }}
                    id="password"
                    type="password"
                    label="Password"
                    margin="normal"
                    variant="filled"
                    onChange={this.setPassword}
                    value={this.state.password}
                  />
                </Box>
                <Button
                  sx={{ background: "#FFFFFF", width: '100%' }}
                  type="submit"
                  variant="outlined">Login</Button>
              </Box>
            </div>
          </Box>
        </form>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={this.state.snackbarOpen}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
        >
          <Alert onClose={this.handleSnackbarClose} severity="error">
            Incorrect username or password.
          </Alert>
        </Snackbar>
      </>
    );
  }

  handleKeyDown = event => {
    if (event.keyCode === 13) {
      this.handleLogin();
    }
  };

  setUsername = event => {
    this.setState({
      username: event.target.value
    });
  };

  setPassword = event => {
    this.setState({
      password: event.target.value
    });
  };

  handleLogin = event => {
    if (event) {
      event.preventDefault();
    }
    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ email: this.state.username, password: this.state.password })
    }
    fetch("/login", requestOptions)
      .then(response => {
        if (response.ok) {
          window.location.href = "/";
          return;
        }
        throw response;
      })
      .catch(error => {
        this.setState({
          snackbarOpen: true
        })
      });
  };

  handleSnackbarClose = (event, reason) => { 
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
        snackbarOpen: false
    })
  };

}